import { css } from 'astroturf';
import React from 'react';
import Circle from './Circle';

const styles = css`
  .brands {
    position: relative;
    padding: 0rem 0 5rem;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    max-width: 82rem;
    color: rgb(10, 21, 48);

    &::after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      bottom: 5rem;
      width: 100px;
      background: linear-gradient(to left, #f7faff, transparent);
      z-index: 2;
    }
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 5rem;
      width: 100px;
      background: linear-gradient(to right, #f7faff, transparent);
      z-index: 2;
    }

    @media (min-width: 600px) {
      display: block;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 1rem;
      margin-top: 0;

      padding-inline: 2rem;
      max-width: 60rem;
      & > span > svg {
        height: 130%;
      }
    }
    p {
      text-wrap: balance;
      font-size: calc(16px + 0.25vw);
      line-height: 1.6;
      font-weight: 400;
      padding-inline: 2rem;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
    & .list {
      position: relative;
      margin: 1rem auto 0;
      display: flex;
      justify-content: space-around;
      align-items: start;
      overflow-x: auto;
      overflow-y: clip;
      scroll-snap-type: x mandatory;

      padding: 2rem;
      gap: 3rem;

      @media (min-width: 600px) {
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        margin-top: 4rem;
        gap: 2rem 3rem;
      }

      div {
        display: flex;
        flex: 1 1 12%;
        white-space: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1.5;
        font-weight: bold;
        font-size: 1.5rem;
        scroll-snap-align: center;

        svg {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }

  .graphicwrap {
    width: 120%;
    left: -10%;
    bottom: -15%;
    height: 700px;
    position: absolute;
    filter: blur(50px);
    z-index: -1;
    opacity: 0.25;
    overflow: hidden;
    max-width: 100vw;
  }
  .graphic {
    inset: 0;
    height: 700px;
    background: linear-gradient(90deg, #0a6a9e 0, rgba(103, 178, 111, 0.6) 100%);
    clip-path: polygon(
      100% 61.6%,
      97.5% 26.9%,
      85.5% 0.1%,
      74.1% 44.1%,
      80.7% 2%,
      72.5% 32.5%,
      52.4% 68.1%,
      60.2% 62.4%,
      47.5% 58.3%,
      27.5% 76.7%,
      0.1% 64.9%,
      45.2% 34.5%,
      17.9% 100%,
      27.6% 76.8%,
      76.1% 97.7%,
      74.1% 44.1%
    );
  }
`;

function Sync(props) {
  return (
    <div className={[styles.brands].join(' ')} tabIndex="0">
      <h2>
        Everything you do is kept <Circle>in sync</Circle>
      </h2>
      <p>
        No matter how many devices you're working with, your actions are mirrored across all of them. Click, scroll,
        type, hover or edit styles on one and see it happen on all.
      </p>
      <div className={styles.list}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-navigation"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 18.5l7.265 2.463c.196 .077 .42 .032 .57 -.116a.548 .548 0 0 0 .134 -.572l-7.969 -17.275l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463" />
          </svg>
          Navigation
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-reload"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
            <path d="M20 4v5h-5" />
          </svg>
          Reloads
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-mouse"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z" />
            <path d="M12 7l0 4" />
          </svg>
          Scroll
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-click"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12l3 0" />
            <path d="M12 3l0 3" />
            <path d="M7.8 7.8l-2.2 -2.2" />
            <path d="M16.2 7.8l2.2 -2.2" />
            <path d="M7.8 16.2l-2.2 2.2" />
            <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
          </svg>
          Clicks
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-hand-finger"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
            <path d="M11 11.5v-2a1.5 1.5 0 1 1 3 0v2.5" />
            <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
            <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
          </svg>
          Hover
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-forms"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3" />
            <path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3" />
            <path d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7" />
            <path d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1" />
            <path d="M17 12h.01" />
            <path d="M13 12h.01" />
          </svg>
          Form input
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
          Inspect
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-pencil"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
            <path d="M13.5 6.5l4 4" />
          </svg>
          DOM Edits
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-grid-pattern"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
            <path d="M10 8v8" />
            <path d="M14 8v8" />
            <path d="M8 10h8" />
            <path d="M8 14h8" />
          </svg>
          Layout Debugging
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-file-type-css"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M8 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
            <path d="M11 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
            <path d="M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
          </svg>
          CSS Edits
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-bolt"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
          </svg>
          Live Reload
        </div>
      </div>
    </div>
  );
}

export default Sync;
